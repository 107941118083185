.ag-body-viewport {
  overflow-y: scroll !important;
}

.ag-header-cell {
  background-color: white;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ag-cell {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.ag-cell-wrap-text {
  display: table;
  vertical-align: middle;
}

.ag-cell-wrap-text .ag-cell-wrapper {
  word-break: break-word;
  line-height: 20px !important;
  height: content-box;
  display: table;
}

.ag-right-aligned-cell .ag-cell-wrapper {
  width: 100%;
  text-align: right;
  display: block;
}

.ag-cell-not-inline-editing .ag-cell-wrapper .simple-cell-renderer-value {
  width: 100%;
  display: block !important;
  align-items: baseline !important;
}

.ag-cell-wrapper {
  border: none !important;
  height: 100% !important;
  padding-right: 10px !important;
  align-items: baseline;
}

.ag-cell-inline-editing {
  box-shadow: none !important;
}

.tce-editable {
  border: thin solid rgb(186 191 225) !important;
  box-shadow: 0 0 0 1px rgb(186 191 225 / 100%);
}

.page-size-selector {
  .page-size {
    width: 90px;
  }
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 5px;
}

.ag-theme-alpine .ag-cell-inline-editing {
  padding: 0px !important;
}