/* 
 The news modal displays html loaded from the pmrt api
 we want to give the headings more space without impacting other headings
 therefore we need to override the default styles but only inside the modal
*/
.news-modal-body h3 {
  /* font-size: 1.5rem; */
  /* font-weight: 600; */
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}