@charset "utf-8";

body, #root {
  width: 100%;
  height: 100%;
  text-align: left;
}

/* make the cog on the TCE job progress page spin */
.makeItSpin {
  animation: spin 2s infinite linear;
}

/* colour used in the TCE traffic groups table */
.gray {
  color: rgb(186, 186, 186);
}
